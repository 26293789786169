import {utils, write} from 'xlsx-js-style';
import {
    EV_ACCOUNTS_DATA,
    EXPORT_HEADER_ADDRESS,
    EXPORT_HEADER_INTRO,
    EXPORT_HEADER_NAME,
    EXPORT_HEADER_OIB,
    EXPORT_HEADER_PLACE,
    EXPORT_HEADER_REPORT_NAME,
    EXPORT_HEADER_REPORT_OBJECT,
    EXPORT_HEADER_REPORT_TIME,
    EXPORT_INVOICE_HEADER_REPORT_BOOK_NAME,
} from '../constants/stringsAndFields';
import {createHeaderInfo, calculateColumnWidths} from './exportCommon';
import {writeBufferXlsxToFile} from './exportData';
import {getExportUserData} from './helpers';

export const writeInvoicesToXlsxWithHeader = async (data, headerData, fileName, t) => {
    try {
        const wb = utils.book_new();

        // Report Header
        const headerReportInfo = createHeaderInfo(
            t,
            EXPORT_INVOICE_HEADER_REPORT_BOOK_NAME,
            EXPORT_HEADER_REPORT_NAME,
            EXPORT_HEADER_REPORT_TIME,
            EXPORT_HEADER_REPORT_OBJECT,
            headerData?.['dateRange']
        );

        const evAccounts = headerData?.[EV_ACCOUNTS_DATA];
        const multiEvAccounts = evAccounts && evAccounts.length > 1;

        const headerUserData = getExportUserData(evAccounts.pop());
        const headerUserInfo = [
            [{v: t(EXPORT_HEADER_INTRO), t: 's', s: {font: {bold: true}}}],
            [
                {v: t(EXPORT_HEADER_NAME), t: 's', s: {font: {bold: true}}},
                {v: headerUserData[EXPORT_HEADER_NAME], t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_OIB), t: 's', s: {font: {bold: true}}},
                {v: headerUserData[EXPORT_HEADER_OIB], t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_PLACE), t: 's', s: {font: {bold: true}}},
                {v: headerUserData[EXPORT_HEADER_PLACE], t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_ADDRESS), t: 's', s: {font: {bold: true}}},
                {v: headerUserData[EXPORT_HEADER_ADDRESS], t: 's'},
            ],
            [{v: ''}],
        ];

        // Data Header
        const dataNameHeader = [
            [
                ...Object.keys(data[0]).map(name => {
                    return {v: name, t: 's', s: {font: {bold: true}}};
                }),
            ],
        ];

        const dataItems = data.map(item => {
            const out = [];
            Object.values(item).forEach(cell => out.push({v: cell, t: 's'}));
            return out;
        });

        const ws = multiEvAccounts
            ? utils.aoa_to_sheet([...headerReportInfo, ...dataNameHeader, ...dataItems])
            : utils.aoa_to_sheet([...headerReportInfo, ...headerUserInfo, ...dataNameHeader, ...dataItems]);

        // Combine headers and data into one array
        const fullData = [...headerReportInfo, ...dataNameHeader, ...dataItems];
        //caclulate this column sizes dynamically by counting chars for each cell
        //Redni broj	Broj računa	Nadnevak	Ime i prezime	Iznos računa Način plaćanja
        const wscols = calculateColumnWidths(fullData);
        ws['!cols'] = wscols;

        utils.book_append_sheet(wb, ws, t(EXPORT_INVOICE_HEADER_REPORT_BOOK_NAME));

        const wbout = write(wb, {type: 'buffer', bookType: 'xlsx'});
        await writeBufferXlsxToFile(wbout, fileName, t);
    } catch (error) {
        console.error(`Error while writing invoices XLSX file: ${fileName}`, error);
    }
};
