import {DateTime} from 'luxon';
import React, {useState, useEffect} from 'react';
import {DatePickerModal} from 'react-native-paper-dates';
import WithProviders from './PaperPickersTheme';
import {useTranslation} from 'react-i18next';
import {sleep} from '../../utils/promiseHelpers';

const firstDayOfThisWeek = DateTime.local().startOf('week').toJSDate();
const lastDayOfThisWeek = DateTime.local().endOf('week').toJSDate();
const initRange = {startDate: firstDayOfThisWeek, endDate: lastDayOfThisWeek};

const DateRangePicker = ({
    open,
    setOpen,
    onConfirm,
    optionalData = null,
    pickerData = null,
    label = null,
    startLabel = null,
    endLabel = null,
}) => {
    const [range, setRange] = useState(initRange);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [quickSelect, setQuickSelect] = useState(false);
    const {i18n} = useTranslation();

    useEffect(() => {
        if (pickerData) {
            const {stayFromDate, stayToDate} = pickerData;
            if (!stayFromDate) {
                setSaveDisabled(true);
                setQuickSelect(true);
            }
            setRange({startDate: stayFromDate, endDate: stayToDate});
        }
    }, [pickerData]); // eslint-disable-line react-hooks/exhaustive-deps

    const onDismiss = () => {
        setRange(initRange);
        setOpen(false);
    };

    const onDialogConfirm = async ({startDate, endDate}) => {
        setRange({startDate, endDate});
        setOpen(false);
        await sleep(75);
        await onConfirm({startDate, endDate}, optionalData);
    };

    const onChangeDate = async changeEvent => {
        if (quickSelect) {
            const {startDate, endDate} = changeEvent;
            if (startDate && endDate) {
                await onDialogConfirm(changeEvent);
            }
        }
    };

    const pickerComponent = (
        <DatePickerModal
            locale={i18n.language}
            mode="range"
            visible={open}
            onDismiss={onDismiss}
            startDate={range.startDate}
            endDate={range.endDate}
            onConfirm={onDialogConfirm}
            label={label}
            startLabel={startLabel}
            endLabel={endLabel}
            onChange={onChangeDate}
            saveLabelDisabled={saveDisabled}
            saveLabel={quickSelect ? ' ' : null}
            disableStatusBarPadding={false}
            startWeekOnMonday={true}

            // validRange={{
            //   startDate: new Date(2021, 1, 2),  // optional
            //   endDate: new Date(), // optional
            //   disabledDates: [new Date()] // optional
            // }}
            // onChange={} // same props as onConfirm but triggered without confirmed by user
            // saveLabel="Save" // optional
            // uppercase={false} // optional, default is true
            // label="Select period" // optional
            // startLabel="From" // optional
            // endLabel="To" // optional
            // animationType="slide" // optional, default is slide on ios/android and none on web
        />
    );

    return <WithProviders component={pickerComponent} />;
};

export default DateRangePicker;
