import {DateTime, Interval} from 'luxon';
import trim from 'lodash/trim';
import {
    DATE_OF_BIRTH_FIELD,
    FORESEEN_STAY_UNTIL_FIELD,
    STAY_FROM_FIELD,
    TOURIST_NAME,
    TOURIST_SURNAME,
} from '../constants/stringsAndFields';
import {createTriggerNotification} from './notifications';
import {
    getBirthdayNotificationTime,
    getUserDefaultCheckoutTime,
    getUserDefaultNumberOfNights,
    isBirthdayNotificationsOn,
} from './userUtils';
import {Platform} from 'react-native';

const IS_WEB = Platform.OS === 'web';

export const DATE_FORMAT = 'dd.MM.yyyy';

// 2021-12-18 16:15:06
export const getDateFromSql = sqlString => {
    // remove .412807 from 2021-11-04 18:51:38.412807
    const cleanInput = sqlString?.split('.')[0];
    return DateTime.fromSQL(cleanInput);
};

// 2021-12-18 16:15:06 => dd/MM/yyyy
export const getFormattedDateFromSql = sqlString => {
    try {
        if (sqlString) {
            // support also ISO 2019-06-08T23:59:59Z
            if (sqlString.includes('T')) return DateTime.fromISO(sqlString).toFormat('dd/MM/yyyy');
            const cleanInput = sqlString.split('.')[0];
            return DateTime.fromSQL(cleanInput).toFormat('dd/MM/yyyy');
        }
    } catch (e) {
        console.log(e);
    }
    return '';
};

// 2023-05-08T23:59:59Z => DateTime()
export const getDateTimeFromSql = sqlString => {
    try {
        if (sqlString) {
            // support also ISO 2019-06-08T23:59:59Z
            if (sqlString.includes('T')) return DateTime.fromISO(sqlString);
            const cleanInput = sqlString.split('.')[0];
            return DateTime.fromSQL(cleanInput);
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

// Date() => 2023-07-13T20:50:48
export const getDateTimeToIso = inputDate => {
    try {
        if (inputDate) {
            // 2023-07-13T20:50:48.140+02:00
            const outDate = DateTime.fromJSDate(inputDate).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISO();
            return outDate.includes('.') ? outDate.split('.')?.[0] : outDate;
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const getDateTimeToIsoEndOfDay = inputDate => {
    try {
        if (inputDate) {
            // 2023-07-13T20:50:48.140+02:00
            const outDate = DateTime.fromJSDate(inputDate)
                .set({hour: 23, minute: 59, second: 59, millisecond: 0})
                .toISO();
            return outDate.includes('.') ? outDate.split('.')?.[0] : outDate;
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

// get time range between previous month and next month
export const getMonthBoundaries = inputDate => {
    try {
        if (inputDate) {
            let startDate = DateTime.fromJSDate(inputDate)
                .minus({months: 1})
                .set({day: 1, hour: 0, minute: 0, second: 0, millisecond: 0})
                .toISO();
            startDate = startDate.includes('.') ? startDate.split('.')?.[0] : startDate;
            let endDate = DateTime.fromJSDate(inputDate)
                .plus({months: 1})
                .set({day: 1, hour: 23, minute: 59, second: 59, millisecond: 0})
                .toISO();
            endDate = endDate.includes('.') ? endDate.split('.')?.[0] : endDate;
            if (startDate && endDate) {
                return {startDate, endDate};
            }
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const isGuestDateInPast = date => getDateFromDateString(date) < DateTime.now();

export const isDateInPast = date => date < DateTime.now();

export const isBestBeforeExpired = bestBeforeDate => {
    const date = getDateTimeFromSql(bestBeforeDate);
    return date ? isDateInPast(date) : false;
};

// 2021-12-18 16:15:06 => dd/MM/yyyy
// Unlimited if Date is today + 99 years.
export const getFormattedDateFromSqlOrUnlimited = (sqlString, unlimitedText) => {
    try {
        if (sqlString) {
            // support also ISO 2019-06-08T23:59:59Z
            let date = null;
            if (sqlString.includes('T')) {
                date = DateTime.fromISO(sqlString);
            } else {
                const cleanInput = sqlString.split('.')[0];
                date = DateTime.fromSQL(cleanInput);
            }
            if (date?.isValid) {
                const diff = date.diff(DateTime.now(), ['years']).toObject();
                if (diff) {
                    const diffInYears = Math.round(Math.abs(diff['years']));
                    if (diffInYears > 90) {
                        return unlimitedText;
                    }
                }
                return date.toFormat('dd.MM.yyyy.');
            }
        }
    } catch (e) {
        console.log(e);
    }
    return '';
};

//"YYYYMMDD" format
export const getDate = str => {
    if (str) {
        const y = str.substr(0, 4),
            m = str.substr(4, 2) - 1,
            d = str.substr(6, 2);
        const D = new Date(y, m, d);
        return D.getFullYear() == y && D.getMonth() == m && D.getDate() == d ? D : null;
    }
    return null;
};

export const getCalendarDate = date => {
    let month = 'X',
        day = 'X';
    if (date) {
        month = date.toLocaleString('default', {month: 'short'});
        day = date.getDate();
    }
    return {month, day};
};

// 23:45 => Date()
export const getDateFromTime = time => {
    const timeParts = time.split(':');
    const d = new Date();
    d.setHours(timeParts[0]);
    d.setMinutes(timeParts[1]);
    return d;
};

// 16.02.1988  => 19930216
export const getDateFromCamera = dateString => {
    if (dateString) {
        const date = getDateFromCamDateString(dateString);
        if (date) return DateTime.fromJSDate(date).toFormat('yyyyMMdd');
    }
    return null;
};

//1st: 02/02/1988 => 02021988 // 2nd: 3/30/94 //3rd: 25. 03. 1953. //4th: 25. 03. 1953 // 5th: 3/25/53
export const testMrzDates = () => {
    let test = getDateFromMrzCamera('02/02/1988');
    console.log(test);
    test = getDateFromMrzCamera('3/30/94');
    console.log(test);
    test = getDateFromMrzCamera('25. 03. 1953.');
    console.log(test);
    test = getDateFromMrzCamera('25. 03. 1953');
    console.log(test);
    test = getDateFromMrzCamera('3/25/53');
    console.log(test);
};

const MRZ_DATE_PATTERNS = ['dd/MM/yyyy', 'M/d/yy', 'dd.MM.yyyy'];
export const getDateFromMrzCamera = dateString => {
    if (dateString) {
        try {
            const matchedDate = checkDatePatterns(MRZ_DATE_PATTERNS, cleanDateString(dateString));
            if (matchedDate) return matchedDate;
            console.log('parsedDateTime not valid format: ' + dateString);
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

const cleanDateString = dateString => {
    const cleanDateString = dateString.replace(/\s+/g, '');
    return trim(cleanDateString, '.');
};

const checkDatePatterns = (patterns, dateString) => {
    for (let pattern of patterns) {
        const parsedDate = DateTime.fromFormat(dateString, pattern);
        if (parsedDate.isValid) {
            return correctFutureBirthDate(parsedDate);
        }
    }
    return null;
};

const correctFutureBirthDate = date => {
    if (date <= DateTime.now()) {
        return date.toFormat('yyyyMMdd');
    } else {
        const hundredYearsAgo = date.minus({years: 100});
        return hundredYearsAgo.toFormat('yyyyMMdd');
    }
};

// Tue Feb 02 00:00:00 GMT+01:00 1988 => 19880202
export const getDateFromNativeCamera = dateString => {
    if (dateString) {
        try {
            const splitDate = dateString.split(' ');
            const newDate = `${splitDate[1]} ${splitDate[2]} ${splitDate[5]}`;
            const parsedDateTime = DateTime.fromFormat(newDate, 'MMM dd yyyy');
            if (parsedDateTime.isValid) return parsedDateTime.toFormat('yyyyMMdd');
        } catch (e) {
            console.log(e);
        }
    }
    return null;
};

// 02.02.1988  => Date()
const getDateFromCamDateString = dateString => {
    const parsedDateTime = DateTime.fromFormat(dateString, 'dd.MM.yyyy');
    if (parsedDateTime.isValid) return parsedDateTime.toJSDate();
    return null;
};

// 2022-06-09 => Date()
const getDateFromCalDateString = dateString => {
    const parsedDateTime = DateTime.fromFormat(dateString, 'yyyy-MM-dd');
    if (parsedDateTime.isValid) return parsedDateTime.toJSDate();
    return null;
};

// 2022-06-09 => 20210311
export const getDateStringFromCalDateString = dateString => getStringDate(getDateFromCalDateString(dateString));

// 20210311 => Date()
export const getDateFromDateString = dateString => {
    if (dateString) {
        const parsedDateTime = DateTime.fromFormat(dateString, 'yyyyMMdd');
        if (parsedDateTime.isValid) return parsedDateTime.toJSDate();
    }
    return new Date();
};

// 20210311 => DateTime()
export const getDateTimeFromDateString = dateString => {
    if (dateString) {
        const parsedDateTime = DateTime.fromFormat(dateString, 'yyyyMMdd');
        if (parsedDateTime.isValid) return parsedDateTime;
    }
    return null;
};

// 20210311 => 11/03/2021
export const getFormattedDateFromDateString = dateString => {
    if (dateString) {
        const parsedDateTime = DateTime.fromFormat(dateString, 'yyyyMMdd');
        if (parsedDateTime.isValid) return parsedDateTime.toFormat('dd/MM/yyyy');
    }
    return '';
};

// 20210311 => 11.03
export const getShortFormattedDateFromDateString = dateString => {
    return dateString ? DateTime.fromJSDate(getDateFromDateString(dateString)).toFormat('dd.MM.') : '';
};

// 20210311 => 2022-06-26
export const getCalendarFormattedDateFromDateString = dateString => {
    return dateString ? DateTime.fromJSDate(getDateFromDateString(dateString)).toFormat('yyyy-MM-dd') : '';
};

// 20210311 => 26.06.2022
export const getDateString = dateString => {
    return dateString ? DateTime.fromJSDate(getDateFromDateString(dateString)).toFormat('dd.MM.yyyy') : '';
};

// Date() => 20210311
export const getStringDate = date => {
    if (date) {
        const parsedDateTime = DateTime.fromJSDate(date);
        if (parsedDateTime.isValid) return parsedDateTime.toFormat('yyyyMMdd');
    }
    return '';
};

// Date() => 11.08.2022
export const getStringFormattedDate = date => DateTime.fromJSDate(date).toFormat('dd.MM.yyyy');

// {startDate: Date(), endDate: Date()} => 11.08.2022 - 15.08.2022
export const getFormatedRange = dateRange => {
    const {startDate, endDate} = dateRange;
    return `${getStringFormattedDate(startDate)} - ${getStringFormattedDate(endDate)}`;
};

// Date() => 14:30
export const getStringTime = date => {
    if (date) {
        const parsedDateTime = DateTime.fromJSDate(date);
        if (parsedDateTime.isValid) return parsedDateTime.toFormat('HH:mm');
    }
    return '';
};

// now() => 20210311
export const getNowDate = () => DateTime.now().toFormat('yyyyMMdd');

// now() => 2021 03 11
export const getNowDateSqlite = () => DateTime.now().toFormat('yyyy MM dd');

// now() => 2022-06-26
export const getNowDayDate = () => DateTime.now().toFormat('yyyy-MM-dd');

// now() => 28.07.2022
export const getNowDayDateFormated = () => DateTime.now().toFormat('MM-dd-yyyy');

// now() => 28/07/2022
export const getNowDayDateFormated2 = () => DateTime.now().toFormat('dd/MM/yyyy');

// now() => 28.07.2022
export const getNowDayDateFormated3 = () => DateTime.now().toFormat('dd.MM.yyyy.');

// now() => 28.07.2022
export const getTomorrowDayDateFormated3 = () => DateTime.now().plus({days: 1}).toFormat('dd.MM.yyyy.');

// now() => 5.10.2022. 0:39
export const getNowDayDateReportFormated = () => DateTime.now().toFormat('dd.MM.yyyy. HH:mm');

export const calculateNumberOfNights = (stayFrom, stayUntil) => {
    if (!stayFrom || !stayUntil) {
        return '#NUM!'; // Return an error if dates are invalid or missing
    }

    // Parse the dates using luxon, assuming the date format is 'dd.MM.yyyy HH:mm:ss'
    const stayFromDate = DateTime.fromFormat(stayFrom, 'dd.MM.yyyy HH:mm:ss');
    const stayUntilDate = DateTime.fromFormat(stayUntil, 'dd.MM.yyyy HH:mm:ss');

    // Check if dates are valid
    if (!stayFromDate.isValid || !stayUntilDate.isValid) {
        return '#NUM!'; // Return an error if dates are invalid
    }

    // Calculate the difference in days
    const diffTime = stayUntilDate.diff(stayFromDate, 'days').days;

    return diffTime >= 0 ? Math.ceil(diffTime) : '#NUM!'; // Ensure a non-negative result
};

// now() + 1 day => 20210411
export const getTomorrowDate = () => DateTime.now().plus({days: 1}).toFormat('yyyyMMdd');

// now() + 1 day => tomorrow Date()
export const getTomorrowJsDate = () => DateTime.now().plus({days: 1}).toJSDate();

// now() - 1 month => month ago Date()
export const getMonthAgoJsDate = () => DateTime.now().minus({months: 1}).toJSDate();

// now() + nights day => 20210411
export const getNextNightsDate = nights =>
    DateTime.now()
        .plus({days: Number(nights)})
        .toFormat('yyyyMMdd');

// 2022-06-09 + 1 day => 20210610
export const getNextTomorrowDate = date => {
    if (date) {
        const d = getDateFromCalDateString(date);
        if (d) return DateTime.fromJSDate(d).plus({days: 1}).toFormat('yyyyMMdd');
    }
    return '';
};

// 2022-06-09 + nights day => 20210610
export const getNextNightDate = (date, nights) => {
    if (date) {
        const d = getDateFromCalDateString(date);
        if (d)
            return DateTime.fromJSDate(d)
                .plus({days: Number(nights)})
                .toFormat('yyyyMMdd');
    }
    return '';
};

// now() => 23:45
export const getNowTime = () => DateTime.now().toFormat('HH:mm');

// () => 10:00
export const getDefaultTime = () => '10:00';

export const get120YearsAgo = () => {
    const longTimeAgo = DateTime.now().minus({years: 120}).toJSDate();
    return longTimeAgo ? longTimeAgo : null;
};

export const get20YearsAgo = () => {
    const longTimeAgo = DateTime.now().minus({years: 20}).toJSDate();
    return longTimeAgo ? longTimeAgo : null;
};

export const getYesterday = () => {
    const yesterday = DateTime.now().minus({days: 1}).toJSDate();
    return yesterday ? yesterday : null;
};

export const validBirthRange = {
    startDate: get120YearsAgo(),
    endDate: new Date(),
    disabledDates: null,
};

// Date() diff now()
export const diffDateBirth = birthDate => DateTime.fromJSDate(birthDate).diff(DateTime.now(), ['years']).toObject();

// Date() diff now()
export const diffDateBirthYears = birthDate => {
    try {
        const diff = diffDateBirth(birthDate);
        const yearsDiff = diff?.['years'];
        return Math.abs(yearsDiff);
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const calculateAge = birthday => {
    try {
        if (birthday) {
            const today = DateTime.local().startOf('day');
            const birthDate = DateTime.fromJSDate(birthday).startOf('day');
            const years = today.diff(birthDate, 'years')?.years;
            return years;
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

// 12:00 / 17:00 => /Date(1596967200000+0200)/
export const getCheckInOutTime = checkInOut => {
    const regExp = /\d{2}/g;
    const matches = checkInOut.match(regExp);
    if (matches && matches.length === 4) {
        const checkinDate = new Date();
        checkinDate.setHours(matches[0], matches[1], 0);
        const checkoutDate = new Date();
        checkoutDate.setHours(matches[2], matches[3], 0);
        return {
            checkinTime: `/Date(${checkinDate.getTime()}+0200)/`,
            checkoutTime: `/Date(${checkoutDate.getTime()}+0200)/`,
        };
    }
    return {checkinTime: null, checkoutTime: null};
};

// .NET /Date(1530144000000+0530)/ => JS Date
export const getDateFromAspNetFormat = date => {
    const re = /-?\d+/;
    const m = re.exec(date);
    return new Date(parseInt(m[0], 10));
};

// .NET /Date(1530144000000+0530)/ => millis
export const getDateMillisFromAspNetFormat = date => getDateFromAspNetFormat(date).getTime();

// .NET /Date(1530144000000+0530)/ => 10.08.2021 14:54:00
export const getDateFromAspNetFormatToFormatted = date =>
    date ? DateTime.fromJSDate(getDateFromAspNetFormat(date)).toFormat('dd.MM.yyyy HH:mm:ss') : null;

// .NET /Date(1530144000000+0530)/ => 11/03
export const getDateFromAspNetFormatToShort = date =>
    date ? DateTime.fromJSDate(getDateFromAspNetFormat(date)).toFormat('dd.MM.') : null;

//.NET /Date(1530144000000+0530)/ => 20210311
export const getDateFromAspNetFormatToEvisitor = date =>
    date ? DateTime.fromJSDate(getDateFromAspNetFormat(date)).toFormat('yyyyMMdd') : null;

//.NET /Date(1530144000000+0530)/ => 23:45
export const getTimeFromAspNetFormatToEvisitor = date =>
    date ? DateTime.fromJSDate(getDateFromAspNetFormat(date)).toFormat('HH:mm') : null;

export const unixTimeToFormattedDate = unixTime => {
    const date = new Date(unixTime);
    // Hours part from the timestamp
    const hours = date.getHours();
    // Minutes part from the timestamp
    const minutes = '0' + date.getMinutes();
    // Seconds part from the timestamp
    const seconds = '0' + date.getSeconds();

    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth();

    // Will display time in 12/02/2019 10:30:23 format
    const formattedTime =
        day + '/' + month + '/' + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return formattedTime;
};

export const compareNames = (a, b) => {
    if (a.songName < b.songName) return -1;
    if (a.songName > b.songName) return 1;
    return 0;
};

export const compareDates = (a, b) => {
    if (a.dateCreated > b.dateCreated) return -1;
    if (a.dateCreated < b.dateCreated) return 1;
    return 0;
};

export const isInsideDateRange = (rangeStart, rangeEnd, date) => {
    try {
        if (rangeStart && rangeEnd && date) {
            const time = date.getTime();
            return time >= rangeStart.getTime() && time <= rangeEnd.getTime() ? true : false;
        }
    } catch (e) {
        console.log(e);
    }
    return false;
};

// Date() => 2022-06-20
const formatCalDate = date => {
    if (date) {
        const parsedDateTime = DateTime.fromJSDate(date);
        if (parsedDateTime.isValid) return parsedDateTime.toFormat('yyyy-MM-dd');
    }
    return '';
};

export const getDatesBetween = (startDate, endDate, includeEndDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    while (currentDate < endDate) {
        const newDate = new Date(currentDate);
        dates.push({formatted: formatCalDate(newDate), dayDate: DateTime.fromJSDate(newDate).startOf('day')});
        currentDate.setDate(currentDate.getDate() + 1);
    }
    if (includeEndDate)
        dates.push({formatted: formatCalDate(endDate), dayDate: DateTime.fromJSDate(endDate).startOf('day')});
    return dates;
};

export const isSameDay = (firstDate, secondDate) => DateTime.fromJSDate(firstDate).startOf('day').ts === secondDate.ts;

export const getNightsStayUntil = async () => {
    const nights = await getUserDefaultNumberOfNights();
    return nights ? getNextNightsDate(nights) : getTomorrowDate();
};

export const getNightsStayUntilFromDay = async day => {
    const nights = await getUserDefaultNumberOfNights();
    return nights ? getNextNightDate(day, nights) : getNextTomorrowDate(day);
};

export const getTimeStayUntil = async () => {
    const defaultCheckoutTime = await getUserDefaultCheckoutTime();
    return defaultCheckoutTime ?? getDefaultTime();
};

export const getDaysBetweenDates = (from, to) => {
    try {
        if (from && to) {
            const date1 = DateTime.fromFormat(from, 'yyyyMMdd');
            const date2 = DateTime.fromFormat(to, 'yyyyMMdd');
            const diff = date1.diff(date2, ['days']);
            const days = diff.toObject()?.['days'];
            // this is same day, so diff is 1
            if (days === 0) return '1';
            // convert negative days to positive: e.g. -3 days to 3
            return days ? String(Math.abs(days)) : null;
        }
    } catch (e) {
        console.log(e);
    }
    return null;
};

export const getHigherDateBoundary = date => {
    const currentFormDate = getDateFromDateString(date);
    const today = new Date();
    const biggerDate = currentFormDate > today ? currentFormDate : today;
    const dateBoundary = {endDate: biggerDate};
    return dateBoundary;
};

export const getCurrentYear = () => {
    return new Date().getFullYear();
};

export const checkBirthDayNotification = async (guest, checkin, t) => {
    if (IS_WEB) return;
    const isBirthDayNotifEnabled = await isBirthdayNotificationsOn();
    if (isBirthDayNotifEnabled) {
        try {
            const stayFrom = getDateTimeFromDateString(guest?.[STAY_FROM_FIELD]);
            const stayTo = getDateTimeFromDateString(guest?.[FORESEEN_STAY_UNTIL_FIELD]);
            if (stayFrom && stayTo) {
                const interval = Interval.fromDateTimes(stayFrom, stayTo);
                const dateTimeToCheck = getDateTimeFromDateString(guest?.[DATE_OF_BIRTH_FIELD]);
                if (dateTimeToCheck) {
                    const defaultBirthdayNoticationTime = await getBirthdayNotificationTime();
                    const defaultBirthdayNoticationTimeParts = defaultBirthdayNoticationTime?.split(':');
                    const scheduledHour = defaultBirthdayNoticationTimeParts?.[0] ?? 0;
                    const scheduledMinute = defaultBirthdayNoticationTimeParts?.[1] ?? 0;
                    let thisYearDate = DateTime.fromObject({
                        year: stayFrom.year,
                        month: dateTimeToCheck.month,
                        day: dateTimeToCheck.day,
                        hour: Number(scheduledHour),
                        minute: Number(scheduledMinute),
                    });

                    // check is birthday today
                    const today = DateTime.now().plus({minutes: 1});
                    if (thisYearDate.month === today.month && thisYearDate.day === today.day) {
                        thisYearDate = thisYearDate.set({hour: today.hour, minute: today.minute});
                    }
                    const isBirthdayInsideRange = interval.contains(thisYearDate);
                    if (isBirthdayInsideRange) {
                        console.log(
                            `${guest?.[TOURIST_NAME]} ${guest?.[TOURIST_SURNAME]} has birthday inside this checkin on day: ${dateTimeToCheck.day} ${dateTimeToCheck.month}`
                        );
                        await createTriggerNotification(guest, checkin, thisYearDate.toJSDate(), t);
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
};
