import {utils, write} from 'xlsx-js-style';
import {
    EV_ACCOUNTS_DATA,
    EXPORT_HEADER_ADDRESS,
    EXPORT_HEADER_AGE_REPORT_NAME,
    EXPORT_HEADER_CITIZENSHIP_REPORT_NAME,
    EXPORT_HEADER_GENDERS_REPORT_NAME,
    EXPORT_HEADER_INTRO,
    EXPORT_HEADER_NAME,
    EXPORT_HEADER_OIB,
    EXPORT_HEADER_PLACE,
    EXPORT_HEADER_REPORT_NAME,
    EXPORT_HEADER_REPORT_OBJECT,
    EXPORT_HEADER_REPORT_TIME,
    EXPORT_TOURISTS_HEADER_REPORT_BOOK_NAME,
    FEMALE,
    MALE,
} from '../constants/stringsAndFields';
import {createHeaderInfo, calculateColumnWidths, addNightsAndGuestsToHeader} from './exportCommon';
import {calculateNumberOfNights} from './dateHelper';
import {writeBufferXlsxToFile} from './exportData';
import {getExportUserData} from './helpers';
import capitalize from 'lodash/capitalize';
import dropRight from 'lodash/dropRight';

export const writeTouristsToXlsxWithHeader = async (data, headerData, fileName, t) => {
    try {
        const wb = utils.book_new();

        // Calculate the total number of nights
        const totalNumberOfNights = data.reduce((sum, item) => {
            const numberOfNights = calculateNumberOfNights(item.touristStayFrom, item.touristForseenStayUntil);
            return sum + (typeof numberOfNights === 'number' ? numberOfNights : 0); // add only valid numbers
        }, 0);

        const totalGuests = data?.length;

        // Report Header
        const headerReportInfo = createHeaderInfo(
            t,
            EXPORT_TOURISTS_HEADER_REPORT_BOOK_NAME,
            EXPORT_HEADER_REPORT_NAME,
            EXPORT_HEADER_REPORT_TIME,
            EXPORT_HEADER_REPORT_OBJECT,
            headerData?.['dateRange']
        );

        // Add "Broj noćenja" and "Broj gostiju" to the header
        addNightsAndGuestsToHeader(headerReportInfo, totalNumberOfNights, totalGuests, t);

        const evAccounts = headerData?.[EV_ACCOUNTS_DATA];
        const multiEvAccounts = evAccounts && evAccounts.length > 1;

        const headerUserData = getExportUserData(evAccounts.pop());
        const headerUserInfo = [
            [{v: t(EXPORT_HEADER_INTRO), t: 's', s: {font: {bold: true}}}],
            [
                {v: t(EXPORT_HEADER_NAME), t: 's', s: {font: {bold: true}}},
                {v: headerUserData[EXPORT_HEADER_NAME], t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_OIB), t: 's', s: {font: {bold: true}}},
                {v: headerUserData[EXPORT_HEADER_OIB], t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_PLACE), t: 's', s: {font: {bold: true}}},
                {v: headerUserData[EXPORT_HEADER_PLACE], t: 's'},
            ],
            [
                {v: t(EXPORT_HEADER_ADDRESS), t: 's', s: {font: {bold: true}}},
                {v: headerUserData[EXPORT_HEADER_ADDRESS], t: 's'},
            ],
            [{v: ''}],
        ];

        // Data Header
        // removing last three columns: countryShort, ageRange, genderMale
        const dataNameHeader = [
            [
                ...dropRight(
                    Object.keys(data[0]).map(name => {
                        return {v: name, t: 's', s: {font: {bold: true}}};
                    }),
                    3
                ),
                ...[{v: 'numberOfNights', t: 's', s: {font: {bold: true}}}],
            ],
        ];

        // Data Items
        const dataItems = [];
        data.map(item => {
            const cellData = dropRight(Object.values(item), 3); // removing last three columns: countryShort, ageRange, genderMale

            // Add numberOfNight calculation
            const numberOfNights = calculateNumberOfNights(item.touristStayFrom, item.touristForseenStayUntil);

            const out = [];
            cellData.forEach(cell => out.push({v: cell, t: 's'}));
            out.push({v: numberOfNights, t: 'n'}); // Push numberOfNights to the output row
            dataItems.push(out);
        });

        const ws1 = multiEvAccounts
            ? utils.aoa_to_sheet([...headerReportInfo, ...dataNameHeader, ...dataItems])
            : utils.aoa_to_sheet([...headerReportInfo, ...headerUserInfo, ...dataNameHeader, ...dataItems]);

        //caclulate this column sizes dynamically by counting chars for each cell
        //ownerOib	ownerName	facilityName	accomodationUnit	touristName	touristStayFrom	touristForseenStayUntil	touristStatus
        const wscols1 = calculateColumnWidths([...headerReportInfo, ...dataNameHeader, ...dataItems]);
        ws1['!cols'] = wscols1;
        utils.book_append_sheet(wb, ws1, t(EXPORT_TOURISTS_HEADER_REPORT_BOOK_NAME));

        const countries = {};
        const genders = {[MALE]: 0, [FEMALE]: 0};
        const ageRanges = {
            ['0-12']: 0,
            ['12-18']: 0,
            ['18-30']: 0,
            ['30-40']: 0,
            ['40-50']: 0,
            ['50-60']: 0,
            ['60-75']: 0,
            ['75-120']: 0,
        };
        for (const tourist of data) {
            if (tourist.countryShort) {
                if (countries[tourist.countryShort]) {
                    countries[tourist.countryShort] = countries[tourist.countryShort] + 1;
                } else {
                    countries[tourist.countryShort] = 1;
                }
            }
            if (tourist.genderMale) {
                genders[MALE] = genders[MALE] + 1;
            } else {
                genders[FEMALE] = genders[FEMALE] + 1;
            }
            if (tourist.ageRange && ageRanges.hasOwnProperty(tourist.ageRange)) {
                ageRanges[tourist.ageRange] = ageRanges[tourist.ageRange] + 1;
            }
        }

        // CITIZENSHIP
        const headerCitizenshipReportInfo = createHeaderInfo(
            t,
            EXPORT_HEADER_CITIZENSHIP_REPORT_NAME,
            EXPORT_HEADER_REPORT_NAME,
            EXPORT_HEADER_REPORT_TIME,
            EXPORT_HEADER_REPORT_OBJECT,
            headerData?.['dateRange']
        );

        // Add number of nights and guests to the Citizenship header
        addNightsAndGuestsToHeader(headerCitizenshipReportInfo, totalNumberOfNights, totalGuests, t);

        const citizenshipNameHeader = [
            [
                {v: 'Citizenship', t: 's', s: {font: {bold: true}}},
                {v: 'Count', t: 's', s: {font: {bold: true}}},
                {v: 'numberOfNights', t: 's', s: {font: {bold: true}}},
            ],
        ];

        const citizenshipDataItems = [];
        Object.keys(countries).map(countryName => {
            // Calculate total nights for all guests from this country
            const totalNightsForCountry = data
                .filter(tourist => tourist.countryShort === countryName)
                .reduce(
                    (sum, tourist) =>
                        sum + calculateNumberOfNights(tourist.touristStayFrom, tourist.touristForseenStayUntil),
                    0
                );

            citizenshipDataItems.push([
                {v: countryName, t: 's'},
                {v: countries[countryName], t: 's'},
                {v: totalNightsForCountry, t: 'n'}, // Add total nights for this country
            ]);
        });

        const ws2 = utils.aoa_to_sheet([
            ...headerCitizenshipReportInfo,
            ...citizenshipNameHeader,
            ...citizenshipDataItems,
        ]);
        ws2['!cols'] = wscols1;
        utils.book_append_sheet(wb, ws2, t(EXPORT_HEADER_CITIZENSHIP_REPORT_NAME));

        // GENDERS
        const headerGendersReportInfo = createHeaderInfo(
            t,
            EXPORT_HEADER_GENDERS_REPORT_NAME,
            EXPORT_HEADER_REPORT_NAME,
            EXPORT_HEADER_REPORT_TIME,
            EXPORT_HEADER_REPORT_OBJECT,
            headerData?.['dateRange']
        );

        // Add number of nights and guests to the Gender header
        addNightsAndGuestsToHeader(headerGendersReportInfo, totalNumberOfNights, totalGuests, t);

        const gendersNameHeader = [
            [
                {v: 'Gender', t: 's', s: {font: {bold: true}}},
                {v: 'Count', t: 's', s: {font: {bold: true}}},
                {v: 'numberOfNights', t: 's', s: {font: {bold: true}}},
            ],
        ];

        const gendersDataItems = [];
        Object.keys(genders).map(gender => {
            // Calculate total nights for all guests of this gender
            const totalNightsForGender = data
                .filter(
                    tourist => (tourist.genderMale && gender === MALE) || (!tourist.genderMale && gender === FEMALE)
                )
                .reduce(
                    (sum, tourist) =>
                        sum + calculateNumberOfNights(tourist.touristStayFrom, tourist.touristForseenStayUntil),
                    0
                );

            gendersDataItems.push([
                {v: capitalize(gender), t: 's'},
                {v: genders[gender], t: 's'},
                {v: totalNightsForGender, t: 'n'}, // Add total nights for this gender
            ]);
        });

        const ws3 = utils.aoa_to_sheet([...headerGendersReportInfo, ...gendersNameHeader, ...gendersDataItems]);
        ws3['!cols'] = wscols1;
        utils.book_append_sheet(wb, ws3, t(EXPORT_HEADER_GENDERS_REPORT_NAME));

        // AGE
        const headerAgeReportInfo = createHeaderInfo(
            t,
            EXPORT_HEADER_AGE_REPORT_NAME,
            EXPORT_HEADER_REPORT_NAME,
            EXPORT_HEADER_REPORT_TIME,
            EXPORT_HEADER_REPORT_OBJECT,
            headerData?.['dateRange']
        );

        // Add number of nights and guests to the Age header
        addNightsAndGuestsToHeader(headerAgeReportInfo, totalNumberOfNights, totalGuests, t);

        const gendersAgeHeader = [
            [
                {v: 'Age', t: 's', s: {font: {bold: true}}},
                {v: 'Count', t: 's', s: {font: {bold: true}}},
                {v: 'numberOfNights', t: 's', s: {font: {bold: true}}},
            ],
        ];

        const ageDataItems = [];
        Object.keys(ageRanges).map(ageRange => {
            // Calculate total nights for all guests in this age range
            const totalNightsForAgeRange = data
                .filter(tourist => tourist.ageRange === ageRange)
                .reduce(
                    (sum, tourist) =>
                        sum + calculateNumberOfNights(tourist.touristStayFrom, tourist.touristForseenStayUntil),
                    0
                );

            ageDataItems.push([
                {v: ageRange, t: 's'},
                {v: ageRanges[ageRange], t: 's'},
                {v: totalNightsForAgeRange, t: 'n'}, // Add total nights for this age range
            ]);
        });

        const ws4 = utils.aoa_to_sheet([...headerAgeReportInfo, ...gendersAgeHeader, ...ageDataItems]);
        ws4['!cols'] = wscols1;
        utils.book_append_sheet(wb, ws4, t(EXPORT_HEADER_AGE_REPORT_NAME));

        const wbout = write(wb, {type: 'buffer', bookType: 'xlsx'});
        await writeBufferXlsxToFile(wbout, fileName, t);
    } catch (error) {
        console.error(`Error while writing tourists XLSX file: ${fileName}`, error);
    }
};
