import {
    EXPORT_HEADER_REPORT_OBJECT_ALL,
    EXPORT_HEADER_REPORT_PERIOD,
    EXPORT_HEADER_NUMBER_OF_NIGHTS,
    TITLE_NUMBER_OF_GUESTS,
} from '../constants/stringsAndFields';
import {getNowDayDateReportFormated} from './dateHelper';

export const createHeaderInfo = (t, title, reportName, reportTime, reportObject, dateRange) => {
    return [
        [
            {v: t(reportName), t: 's', s: {font: {bold: true}}},
            {v: t(title), t: 's'},
        ],
        [
            {v: t(reportTime), t: 's', s: {font: {bold: true}}},
            {v: getNowDayDateReportFormated(), t: 's'},
        ],
        [
            {v: t(reportObject), t: 's', s: {font: {bold: true}}},
            {v: t(EXPORT_HEADER_REPORT_OBJECT_ALL), t: 's'},
        ],
        [
            {v: t(EXPORT_HEADER_REPORT_PERIOD), t: 's', s: {font: {bold: true}}},
            {v: dateRange, t: 's'},
        ],
        [{v: ''}], // Empty row
    ];
};

// Helper function to add total nights and guests to the header
export const addNightsAndGuestsToHeader = (headerReportInfo, totalNumberOfNights, totalGuests, t) => {
    headerReportInfo.push([
        {v: t(EXPORT_HEADER_NUMBER_OF_NIGHTS), t: 's', s: {font: {bold: true}}},
        {v: totalNumberOfNights, t: 'n'},
    ]);
    headerReportInfo.push([
        {v: t(TITLE_NUMBER_OF_GUESTS) + ':', t: 's', s: {font: {bold: true}}},
        {v: totalGuests, t: 'n'},
    ]);
    headerReportInfo.push([{v: ''}]); // Empty row for spacing
};

// Helper function to calculate column widths based on content length
export const calculateColumnWidths = data => {
    // Sampling instead of calculating for all rows if dataset is large
    const sampleSize = Math.min(20, data.length);
    const colWidths = data.slice(0, sampleSize)[0].map((_, colIndex) => {
        const maxContentWidth = Math.max(
            ...data
                .slice(0, sampleSize)
                .map(row => (row[colIndex] && row[colIndex].v ? row[colIndex].v.toString().length : 10))
        );
        return {wch: Math.max(maxContentWidth + 1, 10)};
    });
    return colWidths;
};
